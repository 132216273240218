<template>
  <div>
    <el-card style="min-height: 45rem">
      <div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-input v-model="searchForm.title" placeholder="商品名称"></el-input>
          </el-col>
          <el-col :span="12">
            <el-button-group>
              <el-button @click="searchGo" type="primary">搜索</el-button>
              <el-button @click="searchReset">重置</el-button>
            </el-button-group>
          </el-col>
        </el-row>
      </div>
      <el-table row-key="id" :data="list">
        <el-table-column width="80" prop="id" label="编号"></el-table-column>
        <el-table-column width="100" label="商品图">
          <template #default="s">
            <img :src="s.row.pic | tomedia" style="width: 5rem;height: 5rem;border-radius: .5rem" alt="">
          </template>
        </el-table-column>
        <el-table-column width="200" label="商品名称" show-overflow-tooltip>
          <template #default="s">
            <span style="font-weight: 600;font-size: .9rem">{{ s.row.title }}</span>
            <div>{{ typeShow(s.row.type) }}</div>
          </template>
        </el-table-column>
        <el-table-column width="140" label="专题">
          <template #default="s">
            <el-tag size="mini" v-for="(item,key) in s.row.theme_item" :key="key">{{ item.goods_theme.title }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="80" label="权限">
          <template #default="s">
            <div v-if="s.row.scope">
              <el-tag size="mini" v-for="(item,key) in s.row.scope.split(',')" :key="key">{{ userLevel2Tag(item) }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="80" prop="fee" label="价格"></el-table-column>

        <el-table-column label="分类" show-overflow-tooltip>
          <template #default="s">
            {{ cateShow(s.row) }}
          </template>
        </el-table-column>
        <el-table-column prop="fake_sales" label="虚拟销量"></el-table-column>
        <el-table-column prop="stock" label="库存"></el-table-column>
        <el-table-column prop="sort" label="排序"></el-table-column>
        <el-table-column prop="sales" label="销量"></el-table-column>
        <el-table-column :formatter="item=>item.status ? '已上架' : '未上架'" label="状态"></el-table-column>
        <el-table-column fixed="right" width="200" align="center" label="操作">
          <template #header>
            <el-button @click="$router.push({name:'goodsEdit'})" size="mini" type="primary">添加</el-button>
          </template>
          <template #default="s">
            <el-button @click="$router.push({
            name:'goodsEdit',query:{id:s.row.id}
            })" size="mini" type="primary">编辑
            </el-button>
            <el-button @click="del(s.row.id)" size="mini" type="danger">移除</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import {parseGoodsType,cateShow} from "@/view/goods/tools";
import {userLevel2Tag} from "@/view/member/tools";

export default {
  name: "manage",
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      size: 15,
      searchForm:{
        title:"",
      },
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    searchGo(){
      this.page = 1;
      this.list.length =0;
      this.load();
    },
    searchReset(){
      this.searchForm = {
        title:"",
      }
      this.searchGo()
    },
    userLevel2Tag(e){
      return userLevel2Tag(e);
    },
    del(id) {
      this.$api.shop.goods.del({id}).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
    typeShow(type) {
      return parseGoodsType(type);
    },
    cateShow(row) {
      return cateShow(row)
    },
    load() {
      this.$api.shop.goods.search({
        page: this.page,
        preload_theme:true,
        title:this.searchForm.title
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    },
    pageChange(e) {
      this.page = e;
      this.load();
    }
  }
}
</script>

<style scoped>

</style>